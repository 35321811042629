/**
 * Accordion (Top - FAQ section)
 */
const faq = () => {
  const faqQuestion = document.querySelectorAll('.js-accordion');

  if (faqQuestion) {
    faqQuestion.forEach(q => {
      q.addEventListener('click', () => {
        q.classList.toggle('is-active');
      })
    })
  }
}

export default faq