/**
 * Top MV Splide set up
 */
declare var Splide: any;

const splide = () => {
  const mvSlider = document.querySelector('.splide') as any;
  
  if (mvSlider) {
    const splide = new Splide( mvSlider, {
      type: 'loop',
      gap: "20px",
      focus: 'center',
      drag: true,
      wheel: false,
      rewind: true,
      arrows: false,
      autoplay: true,
      keyboard: false,
      pagination: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      speed: 2000,
      interval: 4000,
      rewindSpeed: 2000,
    });
    splide.mount();
  }
}

export default splide