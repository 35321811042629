import accordion from './components/accordion'
import splide from './components/splide'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    accordion()
    splide()
  },
  false
)
